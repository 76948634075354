import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';
import { RouteObject } from 'react-router/lib/router';

import { useLocalStorage } from 'hooks';
import { User, UserRole } from 'types';
import { FlagsProvider } from 'utils/FeatureFlags';

import { PrivateRoutes } from 'routes/PrivateRoutes';
import { PublicRoutes } from 'routes/PublicRoutes';
import { getAuthorizedRoutes } from 'routes/RouteInfo';
import { Routes } from 'routes/Routes';

import { selectEnvironment } from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';
import { setAppUser } from 'store/modules/user/slice';

import { useIdleTimer } from 'hooks/useIdleTimer';

import { Application } from 'components/Core/Application/Application';
import { LayoutWithFooter } from 'components/Core/LayoutWithFooter/LayoutWithFooter';
import { NotFound } from 'components/Shared/NotFound/NotFound';

import { Button } from '@mui/material';

import { DialogWrapper } from '../components/Shared/CustomMui/DialogWrapper/DialogWrapper';
import { getDisabledFeatureList } from '../features/controlFeatures';
import { SESSION_EXTENSION_TIME_IN_SECONDS } from '../shared/constants';

export const ApplicationRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const user = useSelector(selectUser);
  const environment = useSelector(selectEnvironment);
  const dispatch = useDispatch();
  const [showConfirmationPrompt, setShowConfirmationPrompt] =
    useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(
    SESSION_EXTENSION_TIME_IN_SECONDS
  );

  const activityConfirmationHandler = (): void => {
    if (auth?.isAuthenticated && location.pathname !== Routes.LOGIN) {
      setShowConfirmationPrompt(true);
    }
  };

  useEffect(() => {
    if (showConfirmationPrompt) {
      const intervalRef = setInterval(() => {
        if (remainingTime > 0) {
          setRemainingTime((prevState: number) => {
            if (prevState <= 1) {
              clearInterval(intervalRef);
              setRemainingTime(SESSION_EXTENSION_TIME_IN_SECONDS);
              setShowConfirmationPrompt(false);
            }
            return --prevState;
          });
        }
      }, 1000);
    }
  }, [showConfirmationPrompt]);

  const { activate } = useIdleTimer({
    minutes: 5,
    activityConfirmationHandler,
  });
  const [activeRole] = useLocalStorage<string | undefined>(
    'activeRole',
    undefined
  );

  useEffect(() => {
    if (auth?.isAuthenticated && auth.user?.profile) {
      const keyCloakUserRoles = auth.user?.profile?.role as string;
      const userRole =
        activeRole ?? (keyCloakUserRoles.split(',')?.[0] as UserRole);
      dispatch(
        setAppUser({
          ...auth?.user?.profile,
          advertiser_id: auth?.user?.profile['advertiser-id'],
          network_id: auth?.user?.profile['network-id'],
          role: userRole?.trim(),
          roles: keyCloakUserRoles.split(','),
          accessToken: auth?.user?.access_token,
        } as User)
      );
    }
  }, [auth?.isAuthenticated]);

  const privateRoutesPath = auth?.isAuthenticated
    ? getAuthorizedRoutes(Object.values(PrivateRoutes), user?.role).map(
        (route) => {
          return {
            path: route.path,
            element: <Application>{route.element}</Application>,
          };
        }
      )
    : [];

  const publicRoutePath = Object.values(PublicRoutes).map((route) => {
    return {
      path: route.path,
      element: <LayoutWithFooter>{route.element}</LayoutWithFooter>,
    };
  });

  const commonRoutes = [
    {
      path: '*',
      element: (
        <>
          <Application>
            <NotFound />
          </Application>
        </>
      ),
    },
  ];

  let routes: RouteObject[] = [];

  if (!auth?.isLoading) {
    if (
      auth?.isAuthenticated &&
      (auth.user?.expires_at ?? 0 > new Date().getTime())
    ) {
      routes = routes.concat(commonRoutes, privateRoutesPath);
      if (location?.pathname.startsWith(Routes.UNIVERSES)) {
        navigate(
          `${location?.pathname.replace(Routes.UNIVERSES, Routes.AUDIENCES)}${
            location.search
          }`,
          { replace: true }
        );
      }
    } else if (!location?.pathname.startsWith(Routes.LOGIN)) {
      routes.push({
        path: '*',
        element: (
          <Navigate
            replace
            to={`${Routes.LOGIN}?redirect_uri=${location.pathname}`}
          />
        ),
      });
    }
    routes = routes.concat(publicRoutePath);
  }

  const element = useRoutes(routes);

  return (
    <>
      <FlagsProvider features={getDisabledFeatureList(environment)}>
        <>{element}</>
      </FlagsProvider>
      {showConfirmationPrompt && (
        <DialogWrapper
          width={550}
          showCloseIcon={false}
          headerText={t('confirm_activity_title')}
          handleClose={() => setShowConfirmationPrompt(false)}
          dialogContent={t('confirm_activity_message', { remainingTime })}
          dialogActions={
            <Button
              variant="contained"
              onClick={() => {
                activate();
                setShowConfirmationPrompt(false);
              }}
            >
              {t('confirm')}
            </Button>
          }
        />
      )}
    </>
  );
};
