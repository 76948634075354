import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { UserRole } from 'types';

import { PrivateRoutes } from 'routes/PrivateRoutes';
import { getAuthorizedRoutes } from 'routes/RouteInfo';
import { Routes } from 'routes/Routes';

import { selectUser } from 'store/modules/user/selectors';

export const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);

  const auth = useAuth();

  function redirectToUnauthorizedPath(
    redirectToPath: string,
    userRole: UserRole
  ): boolean {
    const authorizedRoutes = getAuthorizedRoutes(
      Object.values(PrivateRoutes),
      userRole
    );

    return !authorizedRoutes.some((x) => matchPath(x.path, redirectToPath));
  }

  useEffect(() => {
    if (auth.isAuthenticated && user) {
      // get redirect_uri from query params
      const searchParams = new URLSearchParams(location.search);
      const redirectUri = searchParams.get('redirect_uri');
      let redirectToPath = redirectUri || Routes.ROOT;

      // check if from is not in the list of authorized routes
      // we don't want to redirect to the not found screen immediately after login
      if (redirectToUnauthorizedPath(redirectToPath, user?.role))
        redirectToPath = Routes.ROOT;

      navigate(redirectToPath, { replace: true });
    }
  }, [auth]);

  return !auth.isAuthenticated ? <>Loading...</> : <></>;
};
