import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { original } from 'immer';
import isEqual from 'lodash/isEqual';
import { Action, AnyAction } from 'redux';
import { User, UserRole } from 'types';

interface State {
  user: null | User;
  draftUser: null | User;
  draftHasChanges: boolean;
}

const initialState: State = {
  user: null,
  draftUser: null,
  draftHasChanges: false,
};

function isUserAction(action: AnyAction): action is Action {
  return action.type.startsWith('user');
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAppUser(state: State, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setUserAdvertiserId(state: State, action: PayloadAction<string>) {
      if (!state.user) return;
      state.user = {
        ...state.user,
        advertiser_id: action.payload,
      };
    },
    setUserActiveRole(state: State, action: PayloadAction<string>) {
      if (!state.user) return;
      state.user = {
        ...state.user,
        role: action.payload as UserRole,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isUserAction, (state) => {
      if (!state.draftUser) return;
      state.draftHasChanges = state.draftHasChanges = !isEqual(
        original(state.user),
        original(state.draftUser)
      );
    });
  },
});

export const { setAppUser, setUserAdvertiserId, setUserActiveRole } =
  slice.actions;
export default slice.reducer;
