import { useTranslation } from 'react-i18next';

import { useStoreDispatch } from 'store';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';

import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';

interface Props {
  rejectReason: string;
  disableSave: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
}
export const RejectReasonDialog = ({
  onCancel,
  onConfirm,
  rejectReason,
  disableSave,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  return (
    <DialogWrapper
      width={800}
      headerText={t('reject_campaign')}
      subHeaderText={t('reject_campaign_description')}
      handleClose={onCancel}
      dialogContent={
        <TextField
          id="reject-reason"
          datat-testid="reject-reason"
          multiline
          rows={4}
          fullWidth
          value={rejectReason}
          sx={{ mb: (theme) => theme.spacing(2), height: '150' }}
          onChange={(e) =>
            dispatch(updateDraft({ reject_reason: e.target.value }))
          }
          onBlur={() => dispatch(setDraftTouchedFields('reject_reason'))}
        />
      }
      dialogActions={
        <>
          <Button onClick={onCancel} variant="outlined">
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={disableSave}
            onClick={onConfirm}
          >
            {t('confirm')}
          </LoadingButton>
        </>
      }
    />
  );
};
