import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { useFeature } from 'utils/FeatureFlags';

import { Routes } from 'routes/Routes';

import { useStoreDispatch } from 'store';
import { baseApi } from 'store/api/baseApi';
import { useGetNetworkAdvertisersQuery } from 'store/api/endpoints/settings';
import { selectUser } from 'store/modules/user/selectors';
import { setUserAdvertiserId } from 'store/modules/user/slice';

import { useLocalStorage } from 'hooks/useLocalStorage';

import { Button, MenuItem, Select } from '@mui/material';

import { AddAdvertiserDialog } from './AddAdvertiserDialog/AddAdvertiserDialog';
import styles from './AdvertiserDropdown.module.scss';

export const AdvertisersDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useStoreDispatch();
  const user = useSelector(selectUser);

  const [selectedAdvertiserId, setSelectedAdvertiserId] = useLocalStorage(
    'advertiserId',
    ''
  );
  const [advertiserMenuItems, setAdvertiserMenuItems] = useState<
    { label: string; value: string }[]
  >([]);
  const [isAddAdvertiserDialogShown, setIsAddAdvertiserDialogShown] =
    useState<boolean>(false);
  const isCreateAdvertiserEnabled = useFeature(
    'isCreateAdvertiserEnabled'
  ) as boolean;

  const setAdvertiser = (advertiserId: string) => {
    if (advertiserId) {
      setSelectedAdvertiserId(advertiserId);
      dispatch(setUserAdvertiserId(advertiserId));
    }
  };

  const { data: allAdvertisers } = useGetNetworkAdvertisersQuery(
    {
      network_id: user?.network_id!,
    },
    {
      skip: !user?.network_id,
    }
  );

  window.addEventListener('storage', (event: StorageEvent) => {
    if (
      event.key === 'so1-advertiserId' &&
      selectedAdvertiserId !== event.newValue
    ) {
      window.location.reload();
    }
  });

  useEffect(() => {
    const advertiserItems =
      allAdvertisers?.networkAdvertisers?.map((advertiser) => ({
        value: advertiser.id,
        label: advertiser.display_name,
      })) || [];
    advertiserItems.sort((a, b) => a.label.localeCompare(b.label));
    setAdvertiserMenuItems(advertiserItems);
    setAdvertiser(selectedAdvertiserId || advertiserItems[0]?.value);
  }, [allAdvertisers]);

  useEffect(() => {
    if (user?.advertiser_id) setSelectedAdvertiserId(user?.advertiser_id);
  }, [user?.advertiser_id]);

  return (
    <>
      {advertiserMenuItems.length > 0 && (
        <Select
          data-testid="select-advertiser"
          label={''}
          value={selectedAdvertiserId}
          className={classNames({ 'not-selected': !selectedAdvertiserId })}
          required={false}
          MenuProps={{
            className: styles.advertiser_menu,
          }}
        >
          {advertiserMenuItems.map(({ label, value }) => (
            <MenuItem
              key={value}
              value={value}
              data-testid={label}
              onClick={() => {
                setAdvertiser(value);
                dispatch(baseApi.util.resetApiState()); // PACMAN-2556
                navigate(Routes.CAMPAIGNS);
              }}
            >
              {label}
            </MenuItem>
          ))}
          {isCreateAdvertiserEnabled && (
            <MenuItem className={styles.add_advertiser_button}>
              <Button
                className="uppercase"
                variant={'outlined'}
                fullWidth={true}
                onClick={() => setIsAddAdvertiserDialogShown(true)}
              >
                + {t('add_advertiser')}
              </Button>
            </MenuItem>
          )}
        </Select>
      )}
      {isAddAdvertiserDialogShown && (
        <AddAdvertiserDialog
          existingAdvertiserIds={advertiserMenuItems.map(
            (advertiser) => advertiser.value
          )}
          onClose={() => setIsAddAdvertiserDialogShown(false)}
        />
      )}
    </>
  );
};
