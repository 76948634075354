import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useLocalStorage } from 'hooks';
import { xor } from 'lodash';
import {
  ACTIVE_CAMPAIGN_POSSIBLE_STATES,
  DEFAULT_API_PARAMETERS,
} from 'shared/constants';
import {
  ExtendedCampaign,
  ExtendedCampaignGroup,
  PageSettings,
  UserPreferences,
} from 'shared/interfaces';
import { UserRole } from 'types';
import { definitions } from 'types/api';

import { useGetNetworkAdvertisersQuery } from 'store/api/endpoints/settings';
import { selectUser } from 'store/modules/user/selectors';

import {
  CampaignGroupAccordionContext,
  CampaignGroupAccordionContextType,
} from 'components/CampaignGroups/CampaignGroupListing/CampaignGroupAccordion/context/CampaignGroupAccordionContext';
import { Pagination, Table } from 'components/Shared/CustomMui';
import { EmptyState } from 'components/Shared/EmptyState/EmptyState';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import {
  ReportingFilters,
  ReportingPageFilterType,
} from '../ReportingFilters/ReportingFilters';
import {
  GroupReportingMetricsHeaderColumns,
  ReportingColumns,
} from '../components/Columns';
import { useReportingTableData } from '../hooks/useReportingTableData';
import {
  filterGroupTotalMetricsBasedOnRole,
  ReportDataType,
} from '../utils/utils';

interface Props {
  campaignId?: string;
  campaignGroup?: ExtendedCampaignGroup;
  showActionBar?: boolean;
  selectedId?: string;
  onSelect: (reportData: ReportDataType) => void;
  emptyStateMessage?: string;
  sortValue?: {
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  };
  perPage?: number;
  userPreferencesKey: keyof UserPreferences;
  allAdvertisersSelected?: boolean;
}

export const ReportingCampaignTable = ({
  onSelect,
  selectedId,
  campaignId,
  showActionBar = true,
  emptyStateMessage,
  sortValue,
  campaignGroup,
  perPage,
  userPreferencesKey,
  allAdvertisersSelected,
}: Props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const { setNumberOfTableItems } = useContext(
    CampaignGroupAccordionContext
  ) as CampaignGroupAccordionContextType;

  const [isAllAdvertisersEnabled, setIsAllAdvertisersEnabled] =
    useState<boolean>(!!allAdvertisersSelected);

  const [page, setPage] = useState(DEFAULT_API_PARAMETERS.page);
  const [pageSettings, setPageSettings] = useLocalStorage<
    Partial<PageSettings<ReportingPageFilterType>>
  >(userPreferencesKey, {
    filters: { state: [] },
    campaignSort: { field: 'CAMPAIGN_SORT_NAME', order: 'SORT_ORDER_ASC' },
    searchTerm: '',
  });
  const [searchTerm, setSearchTerm] = useState<string>(
    pageSettings.searchTerm || ''
  );
  const [activeSortValue, setActiveSortValue] = useState<{
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  }>(
    pageSettings.campaignSort
      ? pageSettings.campaignSort
      : { field: 'CAMPAIGN_SORT_NAME', order: 'SORT_ORDER_ASC' }
  );
  const [stateFilterValues, setStateFilterValues] = useState<string[]>(
    pageSettings.filters?.['state'].length
      ? pageSettings.filters?.['state']
      : ['CAMPAIGN_STATE_RUNNING']
  );

  const getSortValue = (
    sortValue:
      | {
          field: definitions['CampaignSortField'];
          order: definitions['SortOrder'];
        }
      | undefined
  ) => {
    return (
      sortValue
        ? sortValue
        : { field: 'CAMPAIGN_SORT_UNKNOWN', order: 'SORT_ORDER_DESC' }
    ) as {
      field: definitions['CampaignSortField'];
      order: definitions['SortOrder'];
    };
  };

  const { campaigns, isLoading, numberOfCampaigns } = useReportingTableData({
    page: page,
    campaignId: campaignId,
    campaignGroupIds: campaignGroup?.group_id ? [campaignGroup?.group_id] : [],
    sortField: showActionBar
      ? activeSortValue.field
      : getSortValue(sortValue).field,
    sortOrder: showActionBar
      ? activeSortValue.order
      : getSortValue(sortValue).order,
    advertiserId: !isAllAdvertisersEnabled ? user?.advertiser_id : undefined,
    stateFilterValues: showActionBar ? stateFilterValues : [],
    perPage: perPage || DEFAULT_API_PARAMETERS.per_page_small,
    userRole: user?.role as UserRole,
    search: searchTerm,
  });

  const { data: advertisersData } = useGetNetworkAdvertisersQuery(
    {
      network_id: user?.network_id!,
    },
    {
      skip: !isAllAdvertisersEnabled || !user?.network_id,
    }
  );

  useEffect(() => {
    if (!isLoading && !selectedId && campaigns.length > 0) {
      onSelect(mapToReportObject(campaigns[0]));
    }
  }, [isLoading]);

  useEffect(() => {
    if (numberOfCampaigns) setNumberOfTableItems(numberOfCampaigns);
  }, [numberOfCampaigns]);

  const pagination: Pagination = {
    onPageChange: (_e: MouseEvent, newPage: number) => setPage(newPage),
    page,
    per_page: perPage || DEFAULT_API_PARAMETERS.per_page_small,
    results: numberOfCampaigns || 0,
  };

  const handleFiltering = (values: string[]): void => {
    setPageSettings({
      ...pageSettings,
      filters: {
        state: values,
      },
    });

    if (!values.length) values = ACTIVE_CAMPAIGN_POSSIBLE_STATES;

    if (xor(values, stateFilterValues).length) {
      setPage(1);
      setStateFilterValues(values);
    }
  };

  const onSelectCampaign = (id: string) => {
    const selectedCampaign = campaigns.filter((i) => i.id === id)[0];
    onSelect(mapToReportObject(selectedCampaign));
  };

  const mapToReportObject = (campaign: ExtendedCampaign): ReportDataType => {
    return {
      ...campaign,
      id: campaign.id,
      name: campaign.name,
      objectType: 'Campaign',
      areMultipleCurrenciesInvolved: false,
    };
  };

  return (
    <>
      <LoadingIndicator isAppLoading={isLoading} />
      {showActionBar && (
        <ReportingFilters
          activeSearchTerm={searchTerm}
          activeSortValue={activeSortValue}
          activeStateFilters={stateFilterValues}
          onSearch={(term: string) => {
            setPage(1);
            setSearchTerm(term);
            setPageSettings({
              ...pageSettings,
              searchTerm: term,
            });
          }}
          onFilter={(filterField, values) => handleFiltering(values)}
          onSort={(sortValue, sortOrder) => {
            setPage(1);
            setActiveSortValue({
              field: sortValue,
              order: sortOrder,
            });
            setPageSettings({
              ...pageSettings,
              campaignSort: {
                field: sortValue,
                order: sortOrder,
              },
            });
          }}
          onAllAdvertisersSelectionChange={(value) => {
            setPage(1);
            setIsAllAdvertisersEnabled(value);
          }}
          allAdvertisersSelected={allAdvertisersSelected}
        />
      )}
      <Table
        rows={campaigns.map((campaign) => ({
          ...campaign,
          selected: selectedId === campaign.id,
        }))}
        onRowClick={onSelectCampaign}
        minHeight={!!pagination?.results ? 1 : 2}
        pagination={pagination}
        emptyState={<EmptyState text={emptyStateMessage || t('no_results')} />}
        columns={ReportingColumns(advertisersData?.networkAdvertisers).filter(
          (column) =>
            isAllAdvertisersEnabled ? column : column.field !== 'advertiser_id'
        )}
        selectionType="Radio"
        groupMetricHeaderColumns={GroupReportingMetricsHeaderColumns()}
        groupMetricHeaderRow={
          campaignGroup?.group_id
            ? {
                id: campaignGroup.group_id,
                ...filterGroupTotalMetricsBasedOnRole(
                  campaignGroup,
                  user?.role as UserRole
                ),
              }
            : undefined
        }
      />
    </>
  );
};
