import { RouteObject } from 'react-router-dom';

import { UserRole } from 'types';

export interface RouteInfo extends RouteObject {
  translationKey: string;
  roles: UserRole[];
  path: string;
}

export const getAuthorizedRoutes = (
  routes: RouteInfo[],
  userRole?: UserRole
): RouteInfo[] => {
  if (!userRole) {
    return [];
  }
  return routes.filter(
    (route) =>
      route.roles.length === 0 || // If no roles are defined, all authenticated users can view the page
      route.roles?.map((role) => role.valueOf()).includes(userRole)
  );
};
