import { createSlice } from '@reduxjs/toolkit';
import { AuthConfig } from 'shared/interfaces';
import { definitions } from 'types/api';

import { settingsApi } from 'store/api/endpoints/settings';

import { setEnvironmentConfig } from './actions';

interface State {
  advertiser: null | definitions['Advertiser'];
  channels: definitions['Channel'][];
  countries: definitions['Country'][];
  authConfig: AuthConfig;
  environment: string;
}

const initialState: State = {
  advertiser: null,
  channels: [],
  countries: [],
  authConfig: { client_id: '', authority: '' },
  environment: '',
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setEnvironmentConfig, (state, action) => {
        state.authConfig = action.payload.authConfig;
        state.environment = action.payload.environment;
      })
      .addMatcher(
        settingsApi.endpoints.getAdvertiserById.matchFulfilled,
        (state, { payload }) => {
          state.advertiser = payload.advertiser;
        }
      )
      .addMatcher(
        settingsApi.endpoints.getChannels.matchFulfilled,
        (state, { payload }) => {
          state.channels = payload.channels;
        }
      )
      .addMatcher(
        settingsApi.endpoints.getAllCountries.matchFulfilled,
        (state, { payload }) => {
          state.countries = payload.countries;
        }
      );
  },
});

export default slice.reducer;
