import { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthConfig } from 'shared/interfaces';

import { setEnvironmentConfig as setAuthConfigAction } from '../store/modules/settings/actions';

export const useGetEnvironment = (): {
  environment: string;
  authConfig: AuthConfig | undefined;
} => {
  const dispatch = useDispatch();
  const [environment, setEnvironment] = useState<string>('');
  const [authConfig, setAuthConfig] = useState<AuthConfig>();

  const fetchConfigFile = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.PUBLIC_URL}/config/env_config.json`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      const data = await response.json();
      setEnvironment(data['env']);
      setAuthConfig(data['authConfig']);
      dispatch(
        setAuthConfigAction({
          authConfig: data['authConfig'],
          environment: data['env'],
        })
      );
    } catch (error) {}
  }, [dispatch]);

  useLayoutEffect(() => {
    fetchConfigFile();
  }, []);

  return { environment, authConfig };
};
