import { FC, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as PieChartIcon } from 'assets/icons/chart-pie.svg';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { ReactComponent as FileCheckIcon } from 'assets/icons/file-check.svg';
import { ReactComponent as MegaphoneIcon } from 'assets/icons/megaphone-new.svg';
import { ReactComponent as UserGroupIcon } from 'assets/icons/user-group.svg';
import { TOOLTIP_DELAY } from 'shared/constants';
import Event from 'utils/EventsEmitter';

import { PrivateRoutes } from 'routes/PrivateRoutes';
import { getAuthorizedRoutes, RouteInfo } from 'routes/RouteInfo';

import { selectUser } from 'store/modules/user/selectors';

import { Stack, SvgIcon, Toolbar } from '@mui/material';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import styles from './NavBar.module.scss';

interface NavItem {
  disabled?: boolean;
  icon: ReactNode;
  routeInfo: RouteInfo;
}

const NavBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const navItems: NavItem[] = [
    {
      icon: <SvgIcon component={MegaphoneIcon} viewBox={'0 0 32 32'} />,
      routeInfo: PrivateRoutes.CAMPAIGNS,
    },
    {
      icon: <SvgIcon component={PieChartIcon} viewBox={'0 0 32 32'} />,
      routeInfo: PrivateRoutes.REPORTING,
    },
    {
      icon: <SvgIcon component={EarthIcon} viewBox={'0 0 32 32'} />,
      routeInfo: PrivateRoutes.AUDIENCES,
    },
    {
      icon: <SvgIcon component={FileCheckIcon} viewBox={'0 0 32 32'} />,
      routeInfo: PrivateRoutes.CAMPAIGNS_OVERVIEW,
    },
    {
      icon: <SvgIcon component={UserGroupIcon} viewBox={'0 0 32 32'} />,
      routeInfo: PrivateRoutes.USERS,
    },
  ];

  const authorizedNavItems = navItems.filter((nav) =>
    getAuthorizedRoutes(
      navItems.map((nav) => nav.routeInfo),
      user?.role
    ).includes(nav.routeInfo)
  );

  const selectedIndex = authorizedNavItems.findIndex(
    (item) => item.routeInfo.path === '/' + location?.pathname?.split('/')[1]
  );

  const navItemClickHandler = (index: number) => {
    const path = authorizedNavItems[index].routeInfo.path;
    Event.emit('event/navBarClicked', path);
    navigate(path);
  };

  return (
    <Toolbar className={styles.list_container}>
      <Stack direction="row">
        {authorizedNavItems.map(({ disabled, icon, routeInfo }, index) => (
          <Fragment key={index}>
            <Tooltip
              enterDelay={TOOLTIP_DELAY}
              key={routeInfo.path}
              placement="bottom"
              title={t(routeInfo.translationKey) as string}
            >
              <div
                className={`${styles.list_item} ${
                  index === selectedIndex ? styles.selected : ''
                }`}
                onClick={() => navItemClickHandler(index)}
              >
                <span className={styles.list_item_icon}>{icon}</span>
                <span
                  data-testid={t(routeInfo.translationKey) as string}
                  className={styles.list_item_text}
                >
                  {t(routeInfo.translationKey) as string}
                </span>
              </div>
            </Tooltip>
            <Divider
              className={styles.divider}
              orientation="vertical"
              variant="middle"
              flexItem
            />
          </Fragment>
        ))}
      </Stack>
    </Toolbar>
  );
};

export default NavBar;
