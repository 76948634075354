import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Routes } from 'routes/Routes';

import { setUserAdvertiserId } from 'store/modules/user/slice';

import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';

import { Button, Stack } from '@mui/material';

interface Props {
  campaignAdvertiserId: string;
  toggleDialogVisibility: (isVisible: boolean) => void;
}

export const DifferentAdvertiserConfirmationDialog: FC<Props> = (
  props: Props
): ReactElement => {
  const { campaignAdvertiserId, toggleDialogVisibility } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <DialogWrapper
      headerText={t('different_advertiser_title')}
      handleClose={() => toggleDialogVisibility(false)}
      dialogContent={t('different_advertiser_message')}
      dialogActions={
        <Stack direction="row">
          <Button
            variant="outlined"
            onClick={() => {
              toggleDialogVisibility(false);
              navigate(Routes.CAMPAIGNS);
            }}
            color="secondary"
          >
            {t('dismiss')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              toggleDialogVisibility(false);
              dispatch(setUserAdvertiserId(campaignAdvertiserId));
            }}
          >
            {t('different_advertiser_confirm')}
          </Button>
        </Stack>
      }
      width={600}
      showCloseIcon={false}
    />
  );
};
