import { FC, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCampaigns } from 'hooks';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { Campaign, ExtendedCampaign } from 'shared/interfaces/Campaign';
import { FormType } from 'types';
import { definitions } from 'types/api';
import { isCampaignActive } from 'types/helpers';

import { Routes } from 'routes/Routes';

import {
  useCloneCampaignMutation,
  useGetCampaignsQuery,
} from 'store/api/endpoints/campaign';
import { useGetCumulativesQuery } from 'store/api/endpoints/reports';
import { useGetNetworkAdvertisersQuery } from 'store/api/endpoints/settings';
import { selectChannels } from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';

import { CloneCampaign } from 'components/Campaigns/CloneCampaignDialog/CloneCampaign';
import { Pagination, Table } from 'components/Shared/CustomMui';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import { Grid, Typography } from '@mui/material';

import { EmptyState } from '../EmptyState/EmptyState';
import styles from './CampaignsTable.module.scss';
import { CampaignColumns } from './Columns/CampaignColumns';
import { CampaignGroupColumns } from './Columns/CampaignGroupColumns';

interface Props {
  // Props only for Campaigns section
  stateFilterValues?: string[];
  typeFilterValues?: string[];
  searchField?: string;
  groupAssignment?: definitions['CampaignGroupAssignmentFilter'];
  allAdvertisersSelected?: boolean;
  // Props only for Campaigns Group section
  groupIds?: string[];
  // Props only for Campaigns create/edit page
  campaignGroupFormType?: FormType;
  onUnassignCampaign?: (campaignId: string) => void;
  // Common props for Table
  activeSortValues: {
    field: definitions['CampaignSortField'];
    order: definitions['SortOrder'];
  };
  title?: string;
  actions?: ReactElement;
  setPage: (page: number) => void;
  page: number;
  emptyStateMessage?: string;
}
/**
 * This Table can be used in both Campaign Group and Campaigns section with different props as follows,
 *
 * For Campaigns Section, the following props are considered,
 * `activeSortValues`, `stateFilterValues`, `searchField`, `typeFilterValues` and `isAssigned`.
 *
 * For Campaigns Group, the following props are considered,
 * `activeSortValues` and `groupIds`.
 */
const CampaignsTable: FC<Props> = (props: Props): ReactElement => {
  const {
    setPage,
    page,
    activeSortValues,
    stateFilterValues,
    typeFilterValues,
    searchField,
    title,
    actions,
    groupAssignment,
    groupIds,
    campaignGroupFormType,
    onUnassignCampaign,
    emptyStateMessage,
    allAdvertisersSelected,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const networkChannels = useSelector(selectChannels);

  const [campaignToClone, setCampaignToClone] = useState<ExtendedCampaign>();
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState<boolean>(false);
  const [touchedCampaignIds, setTouchedCampaignIds] = useState<string[]>([]);

  const [cloneCampaign, { isLoading: isCloningCampaign }] =
    useCloneCampaignMutation();

  const isCampaignGroupPage =
    campaignGroupFormType === 'edit' || campaignGroupFormType === 'create';

  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);

  /**
   * To reduce code duplication in parent component, `useGetCampaignsQuery` query endpoint has been moved here.
   * Which helps us to reduce the repetition of same code twice when we call `assigned` and `unassigned` campaigns.
   * And this same query endpoint will also be used in fetching campaigns by group id just by passing `groupIds` via props.
   * When passing campaign group ids, we do not pass advertiser id to avoid ID mismatch between the Adv dropdown selection
   * and the CG advertiser.
   */
  const {
    data: newlyFetchedCampaigns,
    isLoading: isGetCampaignsLoading,
    isFetching: isGetCampaignsFetching,
  } = useGetCampaignsQuery(
    {
      page,
      sortBy: activeSortValues.field,
      per_page: DEFAULT_API_PARAMETERS.per_page_small,
      order: activeSortValues.order,
      filter: !groupIds?.length
        ? {
            advertiser_id: !allAdvertisersSelected
              ? user?.advertiser_id
              : undefined,
            search: searchField,
            state: stateFilterValues,
            type: typeFilterValues,
            group_assignment: groupAssignment,
          }
        : {
            group_assignment: 'ASSIGNED',
            group_id: groupIds,
          },
    },
    {
      skip:
        !user?.advertiser_id ||
        (campaignGroupFormType === 'create' && !groupIds?.length),
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: advertisersData } = useGetNetworkAdvertisersQuery(
    {
      network_id: user?.network_id!,
    },
    {
      skip: !user?.network_id || !allAdvertisersSelected,
    }
  );

  useEffect(() => {
    setCampaignsList(newlyFetchedCampaigns?.results || []);
  }, [newlyFetchedCampaigns]);

  const activeCampaignIds = newlyFetchedCampaigns?.results
    .filter((campaign: Campaign) => isCampaignActive(campaign.state))
    .map((campaign: Campaign) => campaign.id);

  const { data: newCumulatives } = useGetCumulativesQuery(
    activeCampaignIds || [],
    {
      skip: !activeCampaignIds?.length,
      refetchOnMountOrArgChange: true,
    }
  );

  let extendedCampaigns = useCampaigns(
    campaignsList || [],
    newCumulatives as definitions['CumulativeMetric'][],
    networkChannels
  );

  const isCurrentCampaignActive = (campaignId: string) => {
    const currentCampaign = extendedCampaigns.find(
      (campaign) => campaign.id === campaignId
    ) as ExtendedCampaign;
    return isCampaignActive(currentCampaign?.state);
  };

  const cloneClickHandler = (id: string): void => {
    setIsCloneDialogOpen(true);
    setCampaignToClone(
      newlyFetchedCampaigns?.results?.find(
        (campaign: ExtendedCampaign) => campaign?.id === id
      )
    );
  };

  const cloneConfirmationHandler = async (
    cloneCampaignName: string,
    abTest: boolean
  ): Promise<void> => {
    setIsCloneDialogOpen(false);
    const cloneCampaignResponse = await cloneCampaign({
      path: { source_id: campaignToClone?.id as string },
      body: { body: { name: cloneCampaignName, abTest } },
    }).unwrap();
    setIsCloneDialogOpen(false);
    navigate(`${Routes.CAMPAIGNS}/${cloneCampaignResponse?.campaign?.id}/edit`);
  };

  const rowClickHandler = (id: string): void => {
    navigate(`${Routes.CAMPAIGNS}/${id}/edit`);
  };

  const pagination: Pagination = {
    onPageChange: (_e: MouseEvent, newPage: number) => setPage(newPage),
    page,
    per_page: DEFAULT_API_PARAMETERS.per_page_small,
    results: newlyFetchedCampaigns?.query?.results
      ? newlyFetchedCampaigns?.query?.results
      : 0,
  };

  return (
    <>
      <LoadingIndicator
        isAppLoading={
          isGetCampaignsLoading || isGetCampaignsFetching || isCloningCampaign
        }
      />
      <Grid
        container
        className={styles.results_container}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item className={styles.result_count}>
          {title && (
            <>
              <Typography variant="h3">{title}</Typography>
              <Typography variant="h3" color="textSecondary">
                (
                {newlyFetchedCampaigns
                  ? newlyFetchedCampaigns?.query?.results
                  : 0}
                )
              </Typography>
            </>
          )}
        </Grid>
        <Grid item>{actions}</Grid>
      </Grid>
      <Table
        emptyState={<EmptyState text={emptyStateMessage || t('no_results')} />}
        columns={
          isCampaignGroupPage || groupIds?.length
            ? CampaignGroupColumns({
                isCampaignGroupPage,
                setTouchedCampaignIds,
                onUnassignCampaign,
                isCurrentCampaignActive,
                touchedCampaignIds,
                cloneClickHandler,
              })
            : CampaignColumns({
                allAdvertisersSelected,
                cloneClickHandler,
                isCurrentCampaignActive,
                networkAdvertisers: advertisersData?.networkAdvertisers,
              }).filter((column) =>
                allAdvertisersSelected
                  ? column
                  : column.field !== 'advertiser_id'
              )
        }
        minHeight={!!extendedCampaigns.length ? 1 : 2}
        rows={extendedCampaigns}
        onRowClick={rowClickHandler}
        pagination={pagination}
      />
      {isCloneDialogOpen && (
        <CloneCampaign
          onClose={() => setIsCloneDialogOpen(false)}
          campaignToClone={campaignToClone as ExtendedCampaign}
          cloneConfirmationHandler={cloneConfirmationHandler}
        />
      )}
    </>
  );
};
export default CampaignsTable;
