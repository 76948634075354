import { UserProfile } from 'oidc-client-ts';
import { definitions } from 'types/api';

export type User = UserProfile & {
  network_id?: string;
  role: UserRole;
  roles?: UserRole[];
  accessToken?: string;
} & Omit<definitions['User'], 'role'>;

export enum UserRole {
  ADVERTISER_ADMIN = 'advertiser-admin',
  ADVERTISER_CAMPAIGN_MANAGER = 'advertiser-campaign-manager',
  APPROVAL_MANAGER = 'approval-manager',
  KEY_ACCOUNT_MANAGER = 'key-account-manager',
  SALESFORCE_MANAGER = 'salesforce-manager',
  STAFF = 'staff',
}
