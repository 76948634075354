import i18n from 'i18n';
import { ReportSeriesType, UserRole } from 'types';
import { definitions } from 'types/api';

export const tkCampaignState = (state: definitions['CampaignState']): string =>
  ({
    CAMPAIGN_STATE_PAUSED: 'Paused',
    CAMPAIGN_STATE_PAUSED_MANUAL: 'Paused',
    CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED: i18n.t('out_of_budget'),
    CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE: i18n.t('no_promotion'),
    CAMPAIGN_STATE_RUNNING: 'Running',
    CAMPAIGN_STATE_STOPPED: 'Stopped',
    CAMPAIGN_STATE_DRAFT: 'Draft',
    CAMPAIGN_STATE_DELETED: 'Deleted',
    CAMPAIGN_STATE_SUBMITTED: 'Submitted',
    CAMPAIGN_STATE_APPROVED: 'Approved',
    CAMPAIGN_STATE_REJECTED: 'Rejected',
    CAMPAIGN_STATE_UNKNOWN: '',
  }[state]);

export const tkCampaignSortField = (
  sortField: definitions['CampaignSortField']
): string =>
  ({
    CAMPAIGN_SORT_UNKNOWN: '',
    CAMPAIGN_SORT_CAMPAIGN_START: i18n.t('start_date'),
    CAMPAIGN_SORT_CAMPAIGN_END: i18n.t('end_date'),
    CAMPAIGN_SORT_NAME: i18n.t('name'),
    CAMPAIGN_SORT_STATE: i18n.t('state'),
    CAMPAIGN_SORT_BUDGET: i18n.t('budget'),
    CAMPAIGN_SORT_UPDATED_AT: i18n.t('last_modified'),
  }[sortField]);

export const tkCampaignGroupSortField = (
  sortField: definitions['CampaignGroupSortField']
): string =>
  ({
    CAMPAIGN_GROUP_SORT_UNKNOWN: '',
    CAMPAIGN_GROUP_SORT_NAME: i18n.t('name'),
    CAMPAIGN_GROUP_SORT_BUDGET: i18n.t('budget'),
    CAMPAIGN_GROUP_SORT_LAST_MODIFIED: i18n.t('modified_on'),
  }[sortField]);

export const tkCampaignExecutionStatus = (
  executionStatus: definitions['CampaignExecutionStatus']
): string =>
  ({
    STATUS_PREPARING: 'preparing',
    ENGINE_STATUS_SUBMITTED: 'submitted_impulse_calc',
    ENGINE_STATUS_RUNNING: 'submitted_impulse_calc',
    ENGINE_STATUS_FAIL: 'impulse_calc_error',
    ENGINE_STATUS_SUCCESS: 'impulse_calc_success',
    ENGINE_STATUS_CANCELLED: 'impulse_calc_cancelled',
    IMPULSE_STATUS_SUBMITTED: 'submitted_impulse_proc',
    IMPULSE_STATUS_FAIL: 'impulse_proc_error',
    IMPULSE_STATUS_SUCCESS: 'impulse_proc_success',
    IMPULSE_STATUS_CANCELLED: 'impulse_proc_cancelled',
    IMPULSE_STATUS_RUNNING: 'impulse_proc_running',
    STATUS_UNKNOWN: '',
  }[executionStatus]);

export const tkReportSeriesType = (reportSeriesType: ReportSeriesType) =>
  ({
    [ReportSeriesType.CONVERSION_RATE]: 'conversion_rate',
  }[reportSeriesType]);

export const tkUniverseBlockLogic = (
  logic: definitions['BuildUniverseConditionLogic']
): string =>
  ({
    BUILD_UNIVERSE_CONDITION_LOGIC_INCLUDE: 'include',
    BUILD_UNIVERSE_CONDITION_LOGIC_EXCLUDE: 'exclude',
    BUILD_UNIVERSE_CONDITION_LOGIC_NONE: '',
  }[logic]);

export const tkUniverseBlockPeriodType = (
  periodType: definitions['BuildUniverseUserConditionPeriodType']
): string =>
  ({
    BUILDER_CONDITION_PERIOD_TYPE_DAY: 'day',
    BUILDER_CONDITION_PERIOD_TYPE_WEEK: 'week',
    BUILDER_CONDITION_PERIOD_TYPE_UNKNOWN: '',
  }[periodType]);

export const tkCampaignType = (
  campaignType: definitions['CampaignType']
): string =>
  ({
    CAMPAIGN_TYPE_UNKNOWN: 'Unknown',
    CAMPAIGN_TYPE_CONVERSION: 'CPA',
    CAMPAIGN_TYPE_IMPRESSION: 'CPM',
    CAMPAIGN_TYPE_CLICK: 'CPC',
  }[campaignType]);

export const tkPrimaryKpiFieldLabelForChangeLogRecord = (
  campaignType: definitions['CampaignType']
): string =>
  ({
    CAMPAIGN_TYPE_UNKNOWN: '',
    CAMPAIGN_TYPE_CONVERSION: i18n.t('units_purchased'),
    CAMPAIGN_TYPE_IMPRESSION: i18n.t('views'),
    CAMPAIGN_TYPE_CLICK: i18n.t('clicks'),
  }[campaignType]);

export const tkUniverseBuildType = (
  universeBuild: definitions['UniverseBuildType']
): string =>
  ({
    UNIVERSE_BUILD_TYPE_BUILDER: i18n.t('universe_filter_manually_created'),
    UNIVERSE_BUILD_TYPE_EXTERNAL: i18n.t('universe_filter_imported'),
    UNIVERSE_BUILD_TYPE_IMPORT: i18n.t('universe_filter_uploaded_csv'),
    UNIVERSE_BUILD_TYPE_DEFAULT: i18n.t('default'),
    UNIVERSE_BUILD_TYPE_UNKNOWN: '',
  }[universeBuild]);

export const tkUserRole = (role: UserRole | definitions['UserRole']) =>
  ({
    [UserRole.ADVERTISER_ADMIN]: i18n.t(UserRole.ADVERTISER_ADMIN),
    [UserRole.ADVERTISER_CAMPAIGN_MANAGER]: i18n.t(
      UserRole.ADVERTISER_CAMPAIGN_MANAGER
    ),
    [UserRole.APPROVAL_MANAGER]: i18n.t(UserRole.APPROVAL_MANAGER),
    [UserRole.KEY_ACCOUNT_MANAGER]: i18n.t(UserRole.KEY_ACCOUNT_MANAGER),
    [UserRole.SALESFORCE_MANAGER]: i18n.t(UserRole.SALESFORCE_MANAGER),
    [UserRole.STAFF]: i18n.t(UserRole.STAFF),
    USER_ROLE_ADVERTISER_ADMIN: i18n.t(UserRole.ADVERTISER_ADMIN),
    USER_ROLE_ADVERTISER_CAMPAIGN_MANAGER: i18n.t(
      UserRole.ADVERTISER_CAMPAIGN_MANAGER
    ),
    USER_ROLE_KEY_ACCOUNT_MANAGER: i18n.t(UserRole.KEY_ACCOUNT_MANAGER),
    USER_ROLE_UNKNOWN: '',
  }[role]);
