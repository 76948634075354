import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const selectChannels = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.channels
);

export const selectCountries = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.countries
);

export const selectAdvertiser = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.advertiser
);

export const selectAuthConfig = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.authConfig
);

export const selectEnvironment = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.environment
);
