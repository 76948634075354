import { FC } from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from 'types';

import {
  useGetAdvertiserByIdQuery,
  useGetAllCountriesQuery,
  useGetChannelsQuery,
} from 'store/api/endpoints/settings';
import { selectAdvertiser } from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';

import { AdBlockWrapper } from 'components/Core/AdBlockWrapper/AdBlockWrapper';
import { AppS, MainS } from 'components/Core/Application/Application.style';
import Notifications from 'components/Shared/Notifications/Notifications';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { LayoutWithFooter } from '../LayoutWithFooter/LayoutWithFooter';
import NavigationBar from '../Navigation/Bar/Bar';

export const Application: FC = ({ children }) => {
  const userProfile = useSelector(selectUser);
  const user = useSelector(selectUser);
  const advertiser = useSelector(selectAdvertiser);

  const { isFetching: isFetchingAdvertiser } = useGetAdvertiserByIdQuery(
    {
      advertiser_id: userProfile?.advertiser_id!,
    },
    {
      skip: !userProfile?.advertiser_id,
    }
  );
  const { isFetching: isFetchingChannels } = useGetChannelsQuery(
    {
      path: {
        network_id: advertiser?.network_id! || user?.network_id!,
      },
      query: {
        advertiser_id: user?.advertiser_id,
      },
    },
    {
      skip:
        userProfile === null ||
        userProfile?.role === UserRole.STAFF ||
        !(advertiser?.network_id || user?.network_id),
    }
  );
  const { isFetching: isFetchingCountries } = useGetAllCountriesQuery(
    {
      advertiser_id: userProfile?.advertiser_id,
    },
    {
      skip: userProfile === null || userProfile?.role === UserRole.STAFF,
    }
  );

  const isLoading =
    isFetchingAdvertiser || isFetchingChannels || isFetchingCountries;

  return (
    <AppS>
      <NavigationBar />
      <Notifications />
      <MainS>
        <LayoutWithFooter>
          {!isLoading && (
            <ErrorBoundary>
              <AdBlockWrapper>{children}</AdBlockWrapper>
            </ErrorBoundary>
          )}
        </LayoutWithFooter>
      </MainS>
    </AppS>
  );
};
