import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack } from '@mui/material';

import { DialogWrapper } from '../CustomMui/DialogWrapper/DialogWrapper';
import styles from './DiscardConfirmationDialog.module.scss';

interface Props {
  cancelNavigation: () => void;
  confirmNavigation: () => void;
}

export const DiscardConfirmationDialog = ({
  cancelNavigation,
  confirmNavigation,
}: Props) => {
  const { t } = useTranslation();

  const dialogActions: ReactNode = (
    <Stack direction="row">
      <Button
        variant="outlined"
        size="small"
        onClick={cancelNavigation}
        color="secondary"
      >
        {t('discard_prompt_cancel_button_text')}
      </Button>
      <Button variant="contained" size="small" onClick={confirmNavigation}>
        {t('discard_prompt_confirm_button_text')}
      </Button>
    </Stack>
  );

  return (
    <DialogWrapper
      className={styles.dialog_wrapper}
      headerText={t('discard_prompt_header')}
      handleClose={() => {}}
      dialogContent={t('discard_prompt_message')}
      dialogActions={dialogActions}
      width={600}
      showCloseIcon={false}
    />
  );
};
