import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Endpoints } from 'types';
import { definitions, operations } from 'types/api';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in settings
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: [
    'Advertiser',
    'ChannelList',
    'AllChannelList',
    'NetworkAdvertiserList',
    'CountryList',
  ],
});

// inject settings endpoints to the enhancedBaseApiWithTags
export const settingsApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAdvertiserById: builder.query<
      {
        advertiser: definitions['Advertiser'];
      },
      operations['SettingsService_GetAdvertiserByID']['parameters']['path']
    >({
      async queryFn(args, _getState, _extraOptions, fetchWithBQ) {
        const res = await fetchWithBQ({
          url: `${Endpoints.advertisers}/${args.advertiser_id}`,
        });
        const getAdvertiserResponse = res.data as {
          advertiser: definitions['Advertiser'];
        };

        return res.data
          ? { data: { advertiser: getAdvertiserResponse.advertiser } }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['Advertiser'],
    }),
    getChannels: builder.query<
      {
        channels: definitions['Channel'][];
      },
      operations['SettingsService_GetNetworkChannels']['parameters']
    >({
      async queryFn(args, _getState, _extraOptions, fetchWithBQ) {
        const res = await fetchWithBQ({
          url: `${Endpoints.networks}/${args.path?.network_id}/channels`,
          params: {
            advertiser_id: args.query?.advertiser_id,
          },
        });
        const getChannelsResponse =
          res.data as definitions['GetNetworkChannelsResponse'];

        return res.data
          ? { data: { channels: getChannelsResponse.channels } }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['ChannelList'],
    }),
    getAllChannels: builder.query<
      {
        channels: definitions['Channel'][];
      },
      void
    >({
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBQ) {
        const res = await fetchWithBQ({
          url: `${Endpoints.networks}`,
        });
        const networkResponse = res.data as definitions['GetNetworksResponse'];

        const channels = [];

        for (const network of networkResponse.networks) {
          const networkChannelsRes = (
            await fetchWithBQ({
              url: `${Endpoints.networks}/${network.id}/channels`,
            })
          ).data as definitions['GetNetworkChannelsResponse'];

          channels.push(...networkChannelsRes.channels);
        }

        return res.data
          ? { data: { channels: channels } }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['AllChannelList'],
    }),
    getNetworkAdvertisers: builder.query<
      {
        networkAdvertisers: definitions['Advertiser'][];
      },
      operations['SettingsService_GetNetworkAdvertisers']['parameters']['path']
    >({
      async queryFn(args, _path, _extraOptions, fetchWithBQ) {
        const res = await fetchWithBQ({
          url: `${Endpoints.networks}/${args.network_id}/advertisers`,
        });
        const networkAdvertisersResponse =
          res.data as definitions['GetNetworkAdvertisersResponse'];

        return res.data
          ? {
              data: {
                networkAdvertisers: networkAdvertisersResponse.advertisers,
              },
            }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['NetworkAdvertiserList'],
    }),
    createAdvertiser: builder.mutation<
      definitions['Advertiser'],
      {
        advertiser: Pick<definitions['Advertiser'], 'id' | 'display_name'>;
        advertiserAdminUser: definitions['AdvertiserAdminUser'];
      }
    >({
      async queryFn(payload, _, _extraOptions, fetchWithBQ) {
        const res = await fetchWithBQ({
          url: `${Endpoints.advertisers}`,
          method: 'POST',
          body: payload,
        });
        const networkAdvertiser =
          res.data as definitions['CreateAdvertiserResponse'];

        return res.data
          ? {
              data: networkAdvertiser.advertiser,
            }
          : { error: res.error as FetchBaseQueryError };
      },
      invalidatesTags: ['NetworkAdvertiserList'],
    }),
    getAllCountries: builder.query<
      definitions['GetCountriesResponse'],
      operations['SettingsService_GetCountries']['parameters']['query']
    >({
      async queryFn(args, _getState, _extraOptions, fetchWithBQ) {
        const res = await fetchWithBQ({
          url: Endpoints.countries,
          params: {
            query: args.advertiser_id,
          },
        });
        const getCountriesResponse =
          res.data as definitions['GetCountriesResponse'];

        return res.data
          ? {
              data: {
                countries: getCountriesResponse.countries.sort(
                  (
                    country1: definitions['Country'],
                    country2: definitions['Country']
                  ) => (country1.name > country2.name ? 1 : -1)
                ),
              },
            }
          : { error: res.error as FetchBaseQueryError };
      },
      providesTags: ['CountryList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAdvertiserByIdQuery,
  useGetChannelsQuery,
  useGetAllChannelsQuery,
  useLazyGetAllChannelsQuery,
  useGetNetworkAdvertisersQuery,
  useLazyGetNetworkAdvertisersQuery,
  useGetAllCountriesQuery,
  useCreateAdvertiserMutation,
} = settingsApi;
