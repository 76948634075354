import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from 'hooks';
import { tkUserRole } from 'i18n/translationKeyMaps';

import { Routes } from 'routes/Routes';

import { useStoreDispatch } from 'store';
import { selectUser } from 'store/modules/user/selectors';
import { setUserActiveRole } from 'store/modules/user/slice';

import { MenuItem, Select } from '@mui/material';

export const RoleSwitcher = () => {
  const navigate = useNavigate();
  const dispatch = useStoreDispatch();
  const user = useSelector(selectUser);

  const roles = user?.roles;

  const [activeRole, setActiveRole] = useLocalStorage<string | undefined>(
    'activeRole',
    user?.role
  );

  // If user has no roles, return empty fragment
  if (!roles || roles.length <= 1) return <></>;

  const handleChangedRole = (role: string) => {
    dispatch(setUserActiveRole(role));
    setActiveRole(role);
    navigate(Routes.ROOT);
  };

  return (
    <Select
      data-testid="role-switcher"
      value={activeRole}
      onChange={(event) => handleChangedRole(event.target.value as string)}
    >
      {roles?.map((role, index) => (
        <MenuItem key={index} value={role}>
          {tkUserRole(role)}
        </MenuItem>
      ))}
    </Select>
  );
};
