import { useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';

import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { ApplicationRoutes } from 'routes/ApplicationRoutes';
import { Routes } from 'routes/Routes';

import { useGetEnvironment } from 'hooks/useGetEnvironment';

export const App = () => {
  const { authConfig } = useGetEnvironment();
  const [userManagerSettings, setUserManagerSettings] =
    useState<UserManagerSettings>({
      client_id: '',
      authority: '',
      scope: 'openid',
      loadUserInfo: false,
      response_type: 'code',
      automaticSilentRenew: true,
      redirect_uri: origin + Routes.AUTHENTICATION_CALLBACK,
      userStore: new WebStorageStateStore({ store: window.localStorage }),

      monitorSession: true, // this allows cross tab login/logout detection
    });

  useEffect(() => {
    if (!!authConfig) {
      setUserManagerSettings({ ...userManagerSettings, ...authConfig });
    }
  }, [authConfig]);

  return (
    <>
      {userManagerSettings?.client_id ? (
        <AuthProvider {...userManagerSettings}>
          <ApplicationRoutes />
        </AuthProvider>
      ) : (
        <>Loading...</>
      )}
    </>
  );
};
